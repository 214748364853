import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import DinnerContent from '../components/ContentPages/DinnerContent';

function DinnerPage({ data, location }) {
  const sanityContent = data.dinnerpage;

  return (
    <Layout location={location} lang="en">
      <DinnerContent sanityContent={sanityContent} lang="En" />
    </Layout>
  );
}

export const query = graphql`
  query DinnerPageEn {
    dinnerpage: sanityPage(
      id: { eq: "-f5e739b7-d2bf-5511-9350-8115d71aec6f" }
    ) {
      id
      titleEn
      description {
        _rawTextEn
      }
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        alt
      }
      contentSections {
        ... on SanityMenu {
          id
          menuSectionsOne {
            headingEn
            menuItems {
              descriptionEn
              nameEn
              price
              allergensEn
              hidden
            }
          }
          menuSectionsTwo {
            headingEn
            menuItems {
              descriptionEn
              nameEn
              price
              allergensEn
              hidden
            }
          }
        }
      }
    }
  }
`;

export default DinnerPage;
